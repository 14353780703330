import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import './App.css';

import Home from './Home';
import Privacy from './Privacy';
import Licenses from './Licenses';

function App() {
  return (
    <Router>
      <div className="App">
        <Route exact path="/" component={Home} />
        <Route path="/privacy" component={Privacy} />
        <Route path="/licenses" component={Licenses} />
      </div>
    </Router>
  );
}

export default App;
