import React from 'react';
import { Link } from 'react-router-dom';
import logo from './app-icon.png';
import apple from './appleappstore.png';
import google from './googleplay.png';

function Home() {
  return (
    <header className="App-header">
      <img src={logo} className="App-logo" alt="logo" />
      <p className="app-name">To.Me</p>
      <div className="store-links-box">
        <a className="store-links" href="https://apps.apple.com/kr/app/to-me/id1452466978"><img src={apple} alt="Apple AppStore" /></a>
        <a className="store-links" href="https://play.google.com/store/apps/details?id=kr.co.noobee.ToMe"><img src={google} alt="Google Play Store" /></a>
      </div>
      <Link
        className="App-link"
        to="/privacy"
        rel="noopener noreferrer">Privacy Policy</Link>
    </header>
  )
}

export default Home;