import React from 'react'

function Privacy() {
  return (
    <div className="container">
      <div className="privacy">
        <h1>To.Me - Privacy Policy</h1>

        <p>Noobee Inc. ("Noobee", "we", "us", "our") built the To.Me as a Commercial app (the "App"). This Privacy Policy (the "Policy") describes the how we collect and use your personal data through our App. It also describes the rights and options available to you with respect to your personal information.</p>

        <h3>PERSONAL DATA WE COLLECT</h3>
        <p>When you use our App, we will record and collect the following information (collectively, "Analytical information"): </p>
        <ol>
          <li>IP address from which you access the App. We can also identify your geo-location via your IP address.</li>
          <li>Device information, including device type, operating system version, time zone and your preferred languages.</li>
          <li>Usage information, such as the time and date you accessed the App, the features you used, your preferences and for how long you use an App every time you launch it.</li>
        </ol>
        <p><i>The overall personal data outlined above will be referred to as the "Information"</i></p>

        <h3>SOURCE OF PERSONAL DATA</h3>
        <p>We do not collect the Information we disclosed above only by ourselves. We obtain some of it from the following sources ("Vendors"):</p>
        <ol>
          <li>Firebase (Google)</li>
        </ol>
        <p>The Vendors may also collect your Information through their designated code incorporated in our App. We use their services for the purposes set forth below.</p>

        <h3>HOW WE USE PERSONAL DATA</h3>
        <p>We use your Information for the following purposes:</p>
        <ol>
          <li>Maintain and improve our App</li>
          <li>Enrich the content and features available on our App</li>
        </ol>
        <p><i>The legal basis under EU law for processing and collecting Analytical Data is our legitimate interests in operating our App, ongoing management of our business and business development.</i></p>

        <h3>WHEN AND HOW WE SHARE PERSONAL DATA WITH OTHERS</h3>
        <p><i>We will not share your information with third-parties, except in the events listed below or when you provide us your explicit and informed consent</i></p>
        <p>We will share your Information with our service providers who assist us to operate our App. Our service providers are authorized to use your Information only as necessary to provide us with their services and not for their own purposes. We do not sell your Information to third parties.</p>
        <p>If you have breached the terms of use of the App, this Policy or any other agreement you have with Noobee, abused your rights to use the App, or violated any applicable law - Your Information will be shared with third parties (such as legal counsels and advisors), who handle the matter on our behalf, or with the competent authorities.</p>
        <p>If we are bound to disclose your information by a judicial order or by a governmental or regulatory authority acting within its legal authority, we will comply and share the Information.</p>
        <p>If we organize the operation of our company within a different framework, or through another legal structure or entity (such as due to a merger or acquisition) - we will share your Information to enable the structural change. However, the receiving party will be bound by this Policy.</p>

        <h3>SECURITY AND DATA RETENTION</h3>
        <p>We retain personal data as long as we need it for the purposes for which it was obtained or until you ask to delete it. If you ask us to delete the Information, we may still have to retain it to comply with our legal obligations, resolve disputes and enforce our agreements.</p>
        <p>We implement measures to reduce the risks of damage, loss of information and unauthorized access or use of information. However, these measures do not provice absolute information security. Therefore, although efforts are made to secure your personal information, it is not guaranteed, and you cannot expect that the Service will be immune from information security risks.</p>

        <h3>CHANGES TO THIS PRIVACY POLICY</h3>
        <p>We may update this Policy from time to time. Thus, you are advised to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page. These changes are effective immediately after they are posted on this page.</p>

        <h3>CONTACT US</h3>
        <p>If you have any questions or requests concerning your personal data or about our privacy practices and policies, you may contact us at: support@noobee.co.kr</p>
        <h4>Last Update: 2019/08/02</h4>
      </div>
    </div>
  )
}

export default Privacy
